@charset 'UTF-8';
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_burger.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_flex.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_media-queries.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_mixin.scss";

.notfound {
  .footer {
    @include SP {
      margin-top: 54px;
    }
  }
  .page-404 {
    padding: 90px 0 20px;
    @include SP {
      padding: 67px 0 0;
    }
    .title {
      text-align: center;
      font-family: 'Noto Sans JP', sans-serif;
      @include font(38,61.5);
      font-weight: 700;
      color: $color_blue;
      text-transform: uppercase;
      margin-bottom: 28px;
      @include SP {
        @include font(23,40);
        margin-bottom: 35px;
      }
      span {
        display: block;
        @include font(60);
        @include SP {
          @include font(50);
        }
      }
    }
    .txt {
      text-align: center;
      @include font(16,34);
      margin-bottom: 95px;
      @include SP {
        @include font(16,28);
        text-align: left;
        margin-bottom: 45px;
      }
    }
  }
}